import { IElementGenericImgSheet } from "@faro-lotv/ielement-types";
import { reproportionCamera } from "@faro-lotv/lotv";
import { CachedWorldTransform } from "@faro-lotv/project-source";
import { useThree } from "@react-three/fiber";
import { useMemo } from "react";
import {
  Box3,
  Camera,
  Matrix4,
  OrthographicCamera,
  Vector3,
  Vector3Tuple,
  Vector4Tuple,
} from "three";
import { useNonExhaustiveEffect } from "../../hooks";
import { getSheetCenter, getSheetDiagonal } from "../utils/sheet-utils";

export type ModelTransform = {
  /** World 3d position of an IElement */
  position: Vector3Tuple;

  /** World quaternion of an IElement */
  quaternion: Vector4Tuple;

  /** World scale of an IElement */
  scale: Vector3Tuple;
};

export type CameraTargetAndPos = {
  /** The position of the point that the camera will look at. */
  target: Vector3;

  /** The new position of the camera, from where to look at the target. */
  cameraPos: Vector3;
};

/**
 * @returns The camera target pointing to the center of the sheet
 * @param sheet The sheet to look at
 * @param sheetTransform The world transform of the sheet
 */
export function useTargetOnSheet(
  sheet: IElementGenericImgSheet,
  sheetTransform: CachedWorldTransform,
): Vector3 {
  return useMemo(
    () =>
      getSheetCenter(
        sheet,
        new Matrix4().fromArray(sheetTransform.worldMatrix),
      ),
    [sheet, sheetTransform.worldMatrix],
  );
}

/**
 * Place the camera outside the sheet bounding box, with a frustum able to frame both
 * the sheet and the point cloud vertical height
 *
 * @param sheet The sheet to frame
 * @param sheetTransform The world matrix of the sheet
 * @param modelBB The bounding box of the active model object
 * @param modelTransform The world matrix of the model object
 * @param camera The camera to configure
 * @param cameraDistanceFactor the distance of the camera by the scene center as scene radius * factor
 * @returns The target point looked by the camera and the new camera position
 */
export function useCenterCameraOnSheet(
  sheet: IElementGenericImgSheet,
  sheetTransform: CachedWorldTransform,
  modelBB: Box3,
  modelTransform: ModelTransform,
  camera: Camera,
  cameraDistanceFactor = 1,
): CameraTargetAndPos {
  const screenSize = useThree((s) => s.size);

  // Compute the center of the sheet
  const target = useTargetOnSheet(sheet, sheetTransform);

  const radius = useMemo(
    () => getSheetDiagonal(sheet, sheetTransform.scale) / 2,
    [sheet, sheetTransform.scale],
  );

  const newCameraPos = useMemo(() => {
    // Move the camera a little up so we can see the scene from an angle
    const dir = new Vector3(target.x, 0, target.z)
      .normalize()
      .multiplyScalar(radius * cameraDistanceFactor);

    return new Vector3(
      target.x - dir.x,
      target.y - dir.y + radius * cameraDistanceFactor,
      target.z - dir.z,
    );
  }, [radius, target.x, target.y, target.z, cameraDistanceFactor]);

  useNonExhaustiveEffect(() => {
    camera.position.set(newCameraPos.x, newCameraPos.y, newCameraPos.z);

    // Set the camera frustum in order to frame the vertical height of the point cloud
    if (camera instanceof OrthographicCamera) {
      const size =
        Math.max(
          (modelBB.max.y - modelBB.min.y) * modelTransform.scale[0],
          cameraDistanceFactor * radius,
        ) * 0.5;

      camera.top = size;
      camera.bottom = -size;
      camera.right = size;
      camera.left = -size;
      camera.near = 0.1;
      camera.far = size * 10;
      reproportionCamera(camera, screenSize.width / screenSize.height);
    }

    camera.lookAt(target);
  }, []);

  return { target, cameraPos: newCameraPos };
}
