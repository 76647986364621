import { CoreApiClient, CoreApiError } from "@faro-lotv/service-wires";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AuthContext } from "../auth-context";
import { isUlmRedirectMessage, UlmRedirectMessage } from "./ulm-types";
import { getUlmLoginUrl } from "./url-utils";

const HttpCodes = {
  Unauthorized: 401,
  Forbidden: 403,
};

type AuthProviderProps = {
  /** URL of the ULM to use */
  ulmUrl: string;

  /** Method to call when the user logs out */
  onLogout(): void;

  /** Client instance to use to interact with the core API */
  coreApiClient: CoreApiClient;

  /** Reports when the user login status is invalid (Error reported by CoreApi) */
  onLoginFailed(error: unknown): void;

  /** Reports when the user tried to logout but the procedure failed */
  onLogoutFailed(error: unknown): void;

  /**
   * Method to inform the parent if a loading screen should be shown, or not
   *
   * @param shouldShow Indicator for the parent to show or hide a loading screen
   */
  onShowLoadingScreen(shouldShow: boolean): void;
};

/**
 * The Auth Provider will manage the authentication with the Sphere Unified Login Mask (ULM)
 *
 * @returns A component providing the system authentication, selected depending on the runtime config
 */
export function AuthProvider({
  children,
  ulmUrl,
  onLogout,
  coreApiClient,
  onLoginFailed,
  onLogoutFailed,
  onShowLoadingScreen,
}: PropsWithChildren<AuthProviderProps>): JSX.Element | null {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [shouldPromptLogin, setShouldPromptLogin] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(true);

  const iFrameUrl = useMemo(() => getUlmLoginUrl(ulmUrl), [ulmUrl]);

  useEffect(() => {
    async function checkLogin(): Promise<void> {
      setShowLoadingScreen(true);

      try {
        await coreApiClient.getLoggedInUser();
        setIsLoggedIn(true);
      } catch (error) {
        if (
          !(error instanceof CoreApiError) ||
          (error.status !== HttpCodes.Unauthorized &&
            error.status !== HttpCodes.Forbidden)
        ) {
          onLoginFailed(error);
        } else {
          setIsLoggedIn(false);
        }
      }

      setShowLoadingScreen(false);
    }

    checkLogin();
  }, [coreApiClient, onLoginFailed]);

  /**
   * Add event listener for window messages coming from the ULM.
   * Redirect to the received URL if the message is of type UlmRedirectMessage.
   */
  useEffect(() => {
    function onWindowMessage(message: MessageEvent<UlmRedirectMessage>): void {
      if (isUlmRedirectMessage(message.data)) {
        window.location.href = message.data.redirectUrl;
      }
    }

    window.addEventListener("message", onWindowMessage);

    return () => window.removeEventListener("message", onWindowMessage);
  }, []);

  // Inform parent to show loading screen
  useEffect(() => {
    onShowLoadingScreen(showLoadingScreen);
  }, [onShowLoadingScreen, showLoadingScreen]);

  const requestLogin = useCallback(() => {
    setShouldPromptLogin(true);
  }, []);

  /**
   * Logs out the user from the application
   * After a successful logout a new login is requested
   */
  const logout = useCallback(async () => {
    setShowLoadingScreen(true);

    try {
      await coreApiClient.logout();
      onLogout();

      requestLogin();
    } catch (error) {
      onLogoutFailed(error);
    }

    setShowLoadingScreen(false);
  }, [coreApiClient, onLogout, onLogoutFailed, requestLogin]);

  const value = useMemo<AuthContext>(
    () => ({
      isLoggedIn,
      requestLogin,
      logout,
    }),
    [isLoggedIn, logout, requestLogin],
  );

  if (showLoadingScreen) {
    return null;
  }

  return (
    <AuthContext.Provider value={value}>
      {shouldPromptLogin && !isLoggedIn ? (
        <iframe
          title="unified-login-mask"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            border: "none",
          }}
          src={iFrameUrl}
        />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
