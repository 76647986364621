import { GUID, assert, generateGUID } from "@faro-lotv/foundation";
import { useThree } from "@react-three/fiber";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { isEnableableControls } from "./controls-traits";

interface ControlsLockContextData {
  /**
   * Adds a lock on the main scene controls. This disables the controls until all locks are removed.
   *
   * @returns the id of a newly created controls lock. Use to remove a lock.
   */
  addControlsLock(): GUID;

  /** Removes a lock on controls and re-enables the controls if all locks are removed. */
  removeControlsLock(id: GUID): void;
}

export const ControlsLockContext = createContext<
  ControlsLockContextData | undefined
>(undefined);

/**
 * @returns the ControlsLockContext or throws if not available
 */
export function useControlsLockContext(): ControlsLockContextData {
  const ctx = useContext(ControlsLockContext);
  assert(
    ctx,
    "useControlsLockContext() must be used within a ControlsLockContext",
  );
  return ctx;
}

/**
 * Disables the main scene controls while lockControls is set to true.
 * The controls are re-enabled, once all locks in a scene are released.
 *
 * @param lockControls whether the main scene controls should be disabled
 */
export function useControlsLock(lockControls: boolean): void {
  const { addControlsLock, removeControlsLock } = useControlsLockContext();

  useEffect(() => {
    if (lockControls) {
      const lockId = addControlsLock();
      return () => {
        removeControlsLock(lockId);
      };
    }
  }, [addControlsLock, lockControls, removeControlsLock]);
}

/**
 * @returns A context provider that enables its children to lock the controls
 */
export function ControlsLockProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [activeLocks, setActiveLocks] = useState<GUID[]>([]);

  const controls = useThree((s) => s.controls);

  const addControlsLock = useCallback(() => {
    const lockId = generateGUID();
    setActiveLocks((prevLocks) => [...prevLocks, lockId]);
    return lockId;
  }, []);

  const removeControlsLock = useCallback((lockToRemove: GUID) => {
    setActiveLocks((prevLocks) =>
      prevLocks.filter((lock) => lock !== lockToRemove),
    );
  }, []);

  const hasActiveLock = activeLocks.length;

  useEffect(() => {
    if (isEnableableControls(controls)) {
      const prevEnabled = controls.enabled;

      controls.enabled = !hasActiveLock;

      return () => {
        controls.enabled = prevEnabled;
      };
    }
  }, [controls, hasActiveLock]);

  const value = useMemo<ControlsLockContextData>(
    () => ({
      addControlsLock,
      removeControlsLock,
    }),
    [addControlsLock, removeControlsLock],
  );

  return (
    <ControlsLockContext.Provider value={value}>
      {children}
    </ControlsLockContext.Provider>
  );
}
