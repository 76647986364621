import { Matrix4, Object3D } from "three";
import { AbstractRenderingPolicy } from "./AbstractRenderingPolicy";

/**
 * Basic rendering policy to make the 'render on demand' work on a single model.
 */
export class BasicRenderingPolicy extends AbstractRenderingPolicy {
	#model: Object3D | undefined;
	#modelPose = new Matrix4();

	/**
	 * Construct a new BasicRenderingPolicy
	 *
	 * @param o The model to render in the subscene. The subscene is invalidate when this model changes pose
	 */
	constructor(o: Object3D | undefined) {
		super();
		this.#model = o;
	}

	/** Disconnecting all signals and resetting all parameters. */
	dispose(): void {
		super.dispose();
		this.#model = undefined;
	}

	protected modelChanged(): boolean {
		if (!this.#model) return false;
		if (!this.#modelPose.equals(this.#model.matrixWorld)) {
			this.#modelPose.copy(this.#model.matrixWorld);
			return true;
		}
		return false;
	}
}
