import { OVERRIDE_RENDERING_CALL } from "@faro-lotv/spatial-ui";
import { Box, BoxProps } from "@mui/material";
import {
  Camera,
  ComputeFunction,
  RenderCallback,
  Size,
  createPortal,
  useFrame,
  useThree,
} from "@react-three/fiber";
import { throttle } from "lodash";
import {
  PropsWithChildren,
  RefObject,
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Color, Scene, Texture, WebGLRenderer } from "three";
import { ControlsLockProvider } from "../controls/controls-lock-context";
import {
  OffCanvasRenderingLogic,
  RenderingLogic,
  ScissorRenderingLogic,
  areSameRect,
  getElementRectInCanvas,
} from "./view-utils";

/**
 * @param rect DOMRect we want to convert
 * @returns The R3F converted Size object
 */
function sizeFromRect(rect: DOMRect): Size {
  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    left: rect.left,
  };
}

/** A context with data scoped to the current View */
export type ViewContext = {
  /** The function to use to render the scene inside this view */
  renderCallback: RenderCallback | undefined;

  /** The current viewport for this view */
  viewSize: RefObject<Size>;

  /** The renderer used to render the current view */
  renderer: WebGLRenderer | undefined;
};

export const ViewContext = createContext<ViewContext | undefined>(undefined);

export type ViewInternalProps = PropsWithChildren<{
  /** True to render only the scene inside, false to merge with outside scene [Default: true] */
  hasSeparateScene?: boolean;

  /** Name of this view, useful for debug */
  name?: string;

  /** CSS selector to find the element this view need to track*/
  trackingElement: Element;

  /** Custom camera */
  camera?: Camera;

  /** Base scene to use for this View */
  background?: Color | Texture | null;

  /** The optional canvas on which we want to blit the render target framebuffer */
  canvasElement?: HTMLCanvasElement | null;
}>;

/**
 * @returns Internal logic for the View component managing the scissor testing and the render loop
 */
function ViewInternal({
  name,
  hasSeparateScene,
  trackingElement,
  children,
  camera: customCamera,
  background,
  canvasElement,
}: ViewInternalProps): JSX.Element {
  // We need to re-render the component on any update of the r3f store to avoid a state merging bug
  // in createPortal(). Its hook for merging in parent-store data does not update properly with new
  // state values you pass into it. Re-rendering afterwards will ensure that the new state is used.
  // Still this means that for a brief period, there is old data in the store, but until the bug is
  // fixed in r3f, this will at least correct the state again.
  // see https://github.com/pmndrs/react-three-fiber/issues/3253
  useThree();

  const appScene = useThree((s) => s.scene);
  const domElement = useThree((s) => s.gl.domElement);
  const defaultCamera = useThree((s) => s.camera);
  const appRaycaster = useThree((s) => s.raycaster);

  // True if this view is created to track the entire Viewport
  const isMainView = trackingElement === domElement.parentElement;

  // Create a separate scene for all the elements inside this View
  const [viewScene] = useState(() => new Scene());

  // Register this view scene to the main scene
  useLayoutEffect(() => {
    viewScene.visible = isMainView;
    appScene.add(viewScene);
    return () => {
      appScene.remove(viewScene);
    };
  }, [isMainView, appScene, viewScene]);

  // Update the container name
  useEffect(() => {
    if (!isMainView) {
      viewScene.name = name ?? "View";
    }
  }, [viewScene, name, isMainView]);

  // Select what camera to use, the default one or a custom one
  const camera = customCamera ?? defaultCamera;

  // Bounding rect of the current tracking element
  const viewSize = useRef(getElementRectInCanvas(trackingElement, domElement));

  // Slower, but reactive update of the view size to maintain performance during continuous resizes
  // This makes sure that components that rely on useThree's size state update correctly
  const [viewSizeReactive, setViewSizeReactive] = useState(viewSize.current);
  const [setViewSizeThrottled] = useState(() =>
    throttle(setViewSizeReactive, 100, { leading: true, trailing: true }),
  );

  // compute callback is used to override the raycaster with the current viewport and camera
  const compute: ComputeFunction = useCallback(
    (event, state) => {
      if (
        event.target instanceof Node &&
        trackingElement.contains(event.target)
      ) {
        const { width, height, left, top } =
          trackingElement.getBoundingClientRect();
        const x = event.clientX - left;
        const y = event.clientY - top;

        state.raycaster.params = { ...appRaycaster.params };
        state.pointer.set((x / width) * 2 - 1, -(y / height) * 2 + 1);
        state.raycaster.setFromCamera(state.pointer, state.camera);
      }
    },
    [appRaycaster, trackingElement],
  );

  // This object will allow child of the view to customize the rendering function for this view
  const [viewContext] = useState<ViewContext>({
    renderCallback: undefined,
    viewSize,
    renderer: undefined,
  });

  /** Define the auxiliary render target and the buffer storing the pixels value*/
  const renderingPolicy = useRef<RenderingLogic>();

  useEffect(() => {
    const { renderer } = viewContext;
    if (canvasElement) {
      const policy = new OffCanvasRenderingLogic(canvasElement);
      renderingPolicy.current = policy;
      // Keep track of the renderer used by the OffCanvas logic
      viewContext.renderer = policy.renderer;
    } else {
      renderingPolicy.current = new ScissorRenderingLogic(trackingElement);
    }
    return () => {
      renderingPolicy.current?.dispose();
      viewContext.renderer = renderer;
    };
  }, [camera, canvasElement, trackingElement, viewContext]);

  // Override render function for this view
  useFrame((state, delta, frame) => {
    if (!renderingPolicy.current) return;

    const newRect = renderingPolicy.current.init(state, camera);
    if (!areSameRect(viewSize.current, newRect)) {
      viewSize.current = newRect;
      setViewSizeThrottled(newRect);
    }

    // Compute final scene to render
    const scene = hasSeparateScene ? viewScene : appScene;

    // Apply custom background if we have one
    const origBackground = appScene.background;
    if (background) {
      scene.background = background;
    }

    // Render the content of the view, using custom call if defined
    viewScene.visible = true;
    renderingPolicy.current.render(
      viewContext.renderCallback,
      { ...state, scene, camera },
      delta,
      frame,
    );
    viewScene.visible = isMainView || false;

    // Restore state
    appScene.background = origBackground;
    renderingPolicy.current.reset(state);
  }, OVERRIDE_RENDERING_CALL);

  return (
    <>
      {createPortal(
        <ViewContext.Provider value={viewContext}>
          <ControlsLockProvider>{children}</ControlsLockProvider>
        </ViewContext.Provider>,
        viewScene,
        {
          events: {
            compute,
            connected: trackingElement,
          },
          size: sizeFromRect(viewSizeReactive),
          scene: hasSeparateScene ? viewScene : appScene,
          camera,
        },
      )}
    </>
  );
}

export type ViewProps = Omit<ViewInternalProps, "trackingElement"> & {
  /** Handle on the element we want to track for this view */
  trackingElement?: HTMLDivElement | HTMLCanvasElement | null;
};

/**
 * The View component is used to render on part of a canvas and not the entire canvas.
 * It takes as input an element to track (it can be the entire canvas if needed) that will used to
 * compute the rect to render and to receive the events for that View.
 *
 * All the R3F components inside the View will receive an adjusted context, different from the global one
 * that represent the state of this View alone.
 *
 * The event forwarding is a little tricky as a div tracked by a View will steal all the events it receive
 * so any child of that div will never receive events.
 *
 * If you need to overlay other components to your view see { @see ViewDiv } a nice wrapper that will allow to
 * overlay buttons on top of a div that then can be used with the View component safely.
 *
 * A view can be isolated, so render only the components defined inside, or not.
 * An unisolated view will render everything defined outside the View itself and everything defined inside.
 *
 * This allow for example to have some shared objects (like a CAD for example) outside multiple view, and render
 * it from all the views, or only some of them using the threejs layer system
 *
 * @returns A wrapper that will enable scissor testing, track an html element, and manage an internal private scene
 */
export function View({
  trackingElement,
  ...rest
}: ViewProps): JSX.Element | null {
  const canvas = useThree((s) => s.gl.domElement);

  // The tracking element may be undefined (we want a view on the entire canvas)
  // or null (we want a div but it's not ready yet)
  // So we check explicitly for undefined, if the element is null we just need to wait
  const targetElement =
    trackingElement === undefined ? canvas.parentElement : trackingElement;

  if (!targetElement) return null;

  return <ViewInternal trackingElement={targetElement} {...rest} />;
}

type ViewDivProps = Omit<PropsWithChildren<BoxProps>, "component"> & {
  /** Ref to the div to use as an event source for the View class */
  eventDivRef: RefObject<HTMLDivElement>;
};

/**
 * R3F can receive events from a div, but it will not forward them to the child of that div
 * To overlay properly other components on top of the div receiving the events the only solution
 * is to style a parent container, then place the div for R3F with absolute positioning to fill it
 * then place another div with our overlay elements.
 *
 * @returns a div like component used in pair with a {@see View} for scissor testing rendering
 */
export function ViewDiv({
  eventDivRef,
  children,
  ...rest
}: ViewDivProps): JSX.Element {
  return (
    <Box component="div" {...rest}>
      <Box
        component="div"
        sx={{
          position: "relative",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          component="div"
          ref={eventDivRef}
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            zIndex: 0,
          }}
        />
        <Box component="div" sx={{ width: "inherit", height: "inherit" }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}
