import { StatusCodes } from "http-status-codes";
import { ApiResponseError } from "../authentication";
import { isApiResponseMutationErrorMessage } from "./project-api-types";

/**
 * Error to be thrown when the project API responds with an error
 *
 * @deprecated Use `ApiResponseError` instead.
 */
export class ProjectApiError<
  S extends number = number,
  B = Record<string, unknown>,
> extends ApiResponseError<S, B> {
  /** Add a more specific name to this error for better reporting */
  name = "Project API Error";

  /**
   * @param status The HTTP error code
   * @param statusText The corresponding text for the HTTP error code
   * @param body Contains the JSON of the responds, if the API provided one
   */
  constructor(
    public status: S,
    public statusText: string,
    public body?: B,
  ) {
    super(status, statusText, body);
  }
}

/** The body that is returned when a project is NOT linked to Sphere yet. */
export type SphereLinkingNotFoundBody = {
  data: null;
  error: "sphere_linking_not_found";
};

/**
 * Determines if the given error is a SphereLinkingNotFound error.
 *
 * This occurs when the `getSphereProject` endpoint is used, but the project
 * is not linked to Sphere yet.
 *
 * @param error The error to check.
 * @returns `true` if the error is a SphereLinkingNotFound error, else `false`.
 */
export function isSphereLinkingNotFoundError(
  error: unknown,
): error is ApiResponseError<StatusCodes.CONFLICT, SphereLinkingNotFoundBody> {
  if (
    error instanceof ApiResponseError &&
    error.body?.error === "sphere_linking_not_found"
  ) {
    return true;
  }
  return false;
}

/**
 * Determines if the given error is a merge conflict error.
 *
 * This can occur when merging a Capture Tree revision back into the main revision,
 * but when some objects have been changed in the main revision in the meantime.
 *
 * @param error The error to check.
 * @returns `true` if the error is a merge conflict error, else `false`.
 */
export function isMergeConflictError(
  error: unknown,
): error is ApiResponseError<StatusCodes.CONFLICT> {
  return (
    error instanceof ApiResponseError &&
    error.status === StatusCodes.CONFLICT &&
    error.body?.error === "merge_conflict"
  );
}

/** The body that is returned when a project was not found. */
type ProjectNotFoundBody = {
  data: null;
  error: "project_not_found";
};

/**
 * Determines if the given error is a ProjectNotFound error.
 *
 * This occurs when the `getAllIElements` endpoint is used, but the project
 * is too old for being used in the Sphere Viewer.
 *
 * @param error The error to check.
 * @returns `true` if the error is a ProjectNotFound error, else `false`.
 */
export function isProjectNotFoundError(
  error: unknown,
): error is ApiResponseError<StatusCodes.NOT_FOUND, ProjectNotFoundBody> {
  if (
    error instanceof ApiResponseError &&
    error.body?.error === "project_not_found"
  ) {
    return true;
  }
  return false;
}

/**
 * @param error to extract the information from
 * @returns the list of mutation errors, as JSON strings, if the error is from a mutation
 */
export function getMutationErrorMessages(error: ApiResponseError): string[] {
  if (!error.body || !Array.isArray(error.body.data)) {
    return [error.message];
  }
  return error.body.data.map((value) => {
    if (typeof value.message === "string") return value.message;
    return "unknown error";
  });
}

/**
 * @param error to extract the information from
 * @returns the list of problems, as readable strings, reported by the mutation error
 */
export function getMutationErrorProblemsMessages(
  error: ApiResponseError,
): string[] {
  const errObjList = getMutationErrorMessages(error).map((errData) => {
    try {
      return JSON.parse(errData);
    } catch {
      // Fallback to use the original message
      return errData;
    }
  });

  return errObjList.flatMap((error) => {
    if (isApiResponseMutationErrorMessage(error)) {
      return error.Problems.map((problem) => problem.ErrorMessage);
    }
    return error;
  });
}
