/**
 * Assignable feature roles from the AdminPanel.
 * These feature names are defined by the backend.
 */
export enum UserSubscriptionRole {
  /** Basic role indicating that the project has a paid plan and can be used (formerly called "FieldManager") */
  userRoleFm = "user_role_fm",
  userRoleFmTrial = "user_role_fm_trial",

  /** Disables/hides the material-ai UI (otherwise, no function) */
  materialAiProcessingOnly = "material_ai_processing_only",

  /** Feature flag roles */
  ftImage2d = "ft_image_2d",
  ftProjectEvaluation = "ft_project_evaluation",
  ftDownload360 = "ft_download_360",
  ftFaceBlurring = "ft_face_blurring",
  ftSpeedMode = "ft_speed_mode",
  ftSpotwalk = "ft_spotwalk",
  ftPdfExport = "ft_pdf_export",
  ftMaterialAi = "ft_material_ai",

  /** Enables the use of georeferenced sheets */
  webEditorSheetGps = "webeditor-sheet-gps",

  /** Enables GPS-based capturing functionality in the JWA */
  jwaCaptureGps = "jwa_capture_gps",

  /** Project actions the current user is allowed to perform */
  projectArchive = "project-archive",
  projectUnarchive = "project-unarchive",
  projectDownload = "project-download",
  projectCreate = "project-create",

  /**
   * Enabling a company to see any Power BI related UI in the Dashboard (i.e. companyInsights & projectProgress)
   * Note: The company still needs those flags to use Power BI, otherwise they have to request the feature
   */
  insightsTeaser = "insights-teaser",

  /** Enabling the "Premium Analytics" (powered by SiteAI and Power BI) */
  companyInsights = "company-insights",

  /** Enabling the "Project Progress" (powered by SiteAI and Power BI) */
  projectProgress = "project-progress",

  /**
   * Dashboard features returned in a company-context
   * Allows a user to access the "Integrations" tab in the project overview
   */
  dashboardProjects = "dashboard-projects",
  dashboardInsights = "dashboard-insights",
  dashboardSettings = "dashboard-settings",
  dashboardTemplates = "dashboard-templates",
  dashboardIntegrations = "dashboard-integrations",
  dashboardGroups = "dashboard-groups",
  dashboardUserDirectory = "dashboard-user-directory",

  /**
   * Dashboard features returned in a project-context
   * Allows a user to access the "Integrations" tab in the project overview
   */
  dashboardProjectIntegrations = "dashboard-project-integrations",

  /** Enables the creation of Snapshots from other Projects */
  globalSnapshotCreate = "global-snapshot-create",

  /** Enables opening of Snapshots. */
  globalSnapshotView = "global-snapshot-view",

  /** Makes the Visual AI Search feature available. */
  globalVais = "global-vais",

  /** Makes the Video-Mode feature available in the AdminPanel */
  globalVideoMode = "global-videomode",

  /**
   * Makes the progress-ai feature available in the AdminPanel
   * Allows a user to access the "progress-ai" tab in the project overview
   */
  globalProgressAi = "global-progress-ai",

  /** Allows a user to open projects in sphere */
  globalOpenInSphereViewer = "global-open-in-sphere-viewer",

  /** Feature flags for pointclouds */
  globalPointcloudRead = "global-pointcloud-read",
  globalPointcloudWrite = "global-pointcloud-write",
  globalPointcloudSendTo = "global-pointcloud-sendto",

  /** The company has access to the orbis processor */
  globalOrbisProcessing = "global-orbis-processing",

  /** Feature flags for CAD */
  cadDisplay = "cad-display",
  cadImport = "cad-import",

  /** Allowing the user to generate floorplans */
  floorplanGenerator = "floorplan-generator",
}
