import { Box, SxProps, Theme } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { TranslateVar } from "../translation";

/**
 * @returns a human readable string for the remaining time
 * @param expectedEnd the timestamp in ms from epoch of the expected end of the task
 */
function computeRemainingTime(expectedEnd: number): string {
  // Compute the different between the expected end and now
  // TODO: Remove fixed local when we implement Intl support
  let remaining = DateTime.fromMillis(expectedEnd, { locale: "en-US" })
    .diffNow()
    .rescale();

  // Zero out the milliseconds and adjust the units to drop any one of them equal to 0
  remaining = remaining.minus(remaining.milliseconds).rescale();

  // If the remaining is less than 10 seconds show a fixed string
  // as this is an estimate we could go over the estimated expectedEnd and get
  // a negative remaining time
  if (remaining.as("seconds") < 10) {
    return "few seconds";
  }

  // If remaining is more than one day show only the number of days to give an idea
  // of the order of magnitude
  if (remaining.as("days") > 1) {
    remaining = remaining.mapUnits((value, unit) =>
      unit === "days" ? value : 0,
    );
  }

  // If remaining is more than one hour, don't show the seconds
  if (remaining.as("hours") > 1) {
    remaining = remaining.set({ seconds: 0 });
  }

  return remaining
    .rescale()
    .toHuman({
      unitDisplay: "narrow",
    })
    .replace(/,/g, "");
}

type RemainingTimeLabelProps = {
  /** Expected end of task to show in ms from epoch (so it can be compared to Date.now()) */
  expectedEnd: number;

  /** Additional styling */
  sx?: SxProps<Theme>;
};

/**
 * @returns a label showing the remaining time to complete a task if an expected end is available
 */
export function RemainingTimeLabel({
  expectedEnd,
  sx,
}: RemainingTimeLabelProps): JSX.Element | null {
  // The current remaining time to the task completion
  const [remaining, setRemaining] = useState(() =>
    computeRemainingTime(expectedEnd),
  );

  // Update the remaining time once every second
  useEffect(() => {
    // eslint-disable-next-line func-style -- FIXME
    const update = (): void => {
      setRemaining(() => computeRemainingTime(expectedEnd));
    };
    const id = setInterval(update, 1000);

    return () => {
      clearInterval(id);
    };
  }, [expectedEnd]);

  return (
    <Box component="span" sx={sx}>
      <TranslateVar name="remainingTime">{remaining}</TranslateVar> left
    </Box>
  );
}
