import { assert } from "@faro-lotv/foundation";
import {
	AnyMapping,
	DepthTexture,
	EquirectangularReflectionMapping,
	EquirectangularRefractionMapping,
	Mapping,
	Texture,
	UVMapping,
} from "three";

/**
 * Patch the threejs DepthTexture clone that is not really cloning the depth texture
 * it just create a shallow copy that point to the same gpu buffer creating problems
 * in effect pipelines
 *
 * @inheritdoc
 */
DepthTexture.prototype.clone = function clone(): DepthTexture {
	assert(isMapping(this.mapping), "Invalid DepthMap mapping");
	const t = new DepthTexture(
		this.image.width,
		this.image.height,
		this.type,
		this.mapping,
		this.wrapS,
		this.wrapT,
		this.magFilter,
		this.minFilter,
		this.anisotropy,
	);
	t.format = this.format;
	t.source.data = { ...this.source.data };
	return t;
};

const originalClone = Texture.prototype.clone;
Texture.prototype.clone = function clone(): Texture {
	// if (this.isRenderTargetTexture) {
	// 	console.warn("Copying a render target texture can bring unexpected results.");
	// }
	const t = originalClone.apply(this);
	t.isRenderTargetTexture = this.isRenderTargetTexture;
	return t;
};

/**
 * @returns true if the AnyMapping is a valid Mapping
 * @param mapping a AnyMapping value
 */
function isMapping(mapping: AnyMapping): mapping is Mapping {
	return (
		mapping === UVMapping ||
		mapping === EquirectangularReflectionMapping ||
		mapping === EquirectangularRefractionMapping
	);
}
