import {
	Box3,
	BufferGeometry,
	Camera,
	Color,
	Float32BufferAttribute,
	Intersection,
	Material,
	Points,
	Quaternion,
	Raycaster,
	Scene,
	Uint8BufferAttribute,
	Vector3,
	WebGLRenderer,
} from "three";
import { PickingTree } from "../Lod/Picking";
import { LitPointsMaterial } from "../Materials";
import { makeUniform } from "../Materials/Uniforms";
import { MayOwn } from "../Utils/MayOwn";
import {
	DEFAULT_CHUNK_COLOR,
	DEFAULT_CHUNK_NORMAL,
	PointCloudAttributes,
	PointCloudBufferGeometry,
} from "./PointCloudBufferGeometry";
import { PointCloudChunk } from "./PointCloudChunk";

/**
 * Custom raycasting options for a PointCloud
 */
export type PointCloudRaycastingOptions = {
	/** Enable/Disable raycasting entirely (Default: true) */
	enabled?: boolean;

	/** Distance from the ray to consider a point hit (Default: 0.05) */
	threshold?: number;

	/** Settings about the accelerated PickingTree */
	pickingTree: {
		/** Enable/Disable the usage of the PickingTree (Default: true) */
		enabled: boolean;

		/** Enable/Disable the auto update of the picking tree ( @see updatePickingTree ) (Default: true) */
		autoUpdate: boolean;

		/** Max depth of the picking tree (Default: 6) */
		maxDepth: number;
	};
};

/**
 * Options to customize PointCloud behavior
 */
export type PointCloudOptions = {
	/** Additional attributes to allocate */
	attributes?: PointCloudAttributes;

	/** Default color to use when we append a chunk without color to a pointcloud that has colors */
	color?: Color;

	/** Default normal to use when we append a chunk without normals to a pointcloud with normals */
	normal?: Vector3;

	/** True to auto update the bounding sphere when we append or changes points */
	autoUpdateBoundingSphere?: boolean;

	/** A value used to override the materials point size for this particular point cloud */
	pointSizeOverride: number | undefined;

	/** Set to false to disable custom picking and revert to stock 3JS picking */
	raycasting?: PointCloudRaycastingOptions;
};

/**
 * Default settings for raycasting on pointclouds
 */
export const POINT_CLOUD_RAYCASTING_DEFAULTS: Required<PointCloudRaycastingOptions> = {
	enabled: true,
	threshold: 0.05,
	pickingTree: {
		enabled: true,
		autoUpdate: true,
		maxDepth: 6,
	},
};

/**
 * Default values for the point cloud options
 */
const POINT_CLOUD_DEFAULTS: PointCloudOptions = {
	attributes: undefined,
	color: DEFAULT_CHUNK_COLOR,
	normal: DEFAULT_CHUNK_NORMAL,
	autoUpdateBoundingSphere: true,
	pointSizeOverride: undefined,
	raycasting: POINT_CLOUD_RAYCASTING_DEFAULTS,
};

/** Cached Vector3 used during raycasting to not re-create at every raycast */
const RAYCAST_BOX_TARGET = new Vector3();

/**
 * A class to render a pointcloud with a growable storage
 *
 * This class manage the underlying buffer in a way that allows to grow it or change only part of the buffer
 * and only the changed data will be uploaded to the GPU for rendering.
 */
export class PointCloud<PCMaterial extends Material = Material> extends Points<PointCloudBufferGeometry, PCMaterial> {
	/** Options to customize raycasting only for this PointCloud */
	raycasting: Required<PointCloudRaycastingOptions>;

	/** A tree that gets created and used for picking if customPicking is set to true */
	pickingTree?: PickingTree;

	/** A bounding box used to check if a ray intersect this object, cached to not re-create at every raycast */
	#pickingBox = new Box3();

	/** A value used to override the materials point size for this particular point cloud */
	public pointSizeOverride: number | undefined;
	#oldPointSize: number | undefined;

	/** A handle to dispose the geometry only if it's owned by this PointCloud instance */
	#geometryHandle: MayOwn<PointCloudBufferGeometry>;

	/**
	 * Initialize a point cloud from a geometry instance. Will not own the geometry.
	 *
	 * @param geometry The point cloud geometry
	 * @param material The material to use to render this pointcloud (Like: PointsMaterial or Lotv.LitPointsMaterial)
	 * @param options Options used to customize this pointcloud
	 */
	constructor(geometry: PointCloudBufferGeometry, material: PCMaterial, options?: Partial<PointCloudOptions>);
	/**
	 * Initialize an empty point cloud with a specific capacity. Will own the geometry.
	 *
	 * @param capacity Initial point capacity
	 * @param material The material to use to render this pointcloud (Like: PointsMaterial or Lotv.LitPointsMaterial)
	 * @param options Options used to customize this pointcloud
	 */
	constructor(capacity: number, material: PCMaterial, options?: Partial<PointCloudOptions>);
	/**
	 * Initialize a pointcloud from an initial chunk of points. Will own the geometry.
	 *
	 * @param points The initial points
	 * @param material The material to use to render this pointcloud (Like: PointsMaterial or Lotv.LitPointsMaterial)
	 * @param options Options used to customize this pointcloud
	 */
	constructor(points: PointCloudChunk, material: PCMaterial, options?: Partial<PointCloudOptions>);
	/**
	 * Implementation of the constructor
	 *
	 * @param x A size or chunk or float array
	 * @param material The material to use to render this pointcloud (Like: PointsMaterial or Lotv.LitPointsMaterial)
	 * @param _options Options used to customize this pointcloud
	 */
	constructor(
		x: number | PointCloudChunk | PointCloudBufferGeometry,
		material: PCMaterial,
		_options?: Partial<PointCloudOptions>,
	) {
		const options = { ...POINT_CLOUD_DEFAULTS, ..._options };
		const geometry = x instanceof PointCloudBufferGeometry ? x : new PointCloudBufferGeometry(x, options);
		super(geometry, material);
		this.#geometryHandle = new MayOwn(this.geometry, !(x instanceof PointCloudBufferGeometry));
		this.pointSizeOverride = options.pointSizeOverride;
		this.raycasting = { ...POINT_CLOUD_RAYCASTING_DEFAULTS, ...options.raycasting };
	}

	/**
	 * Initialize a point cloud from a geometry instance. The contained points will be removed. Will not own the geometry.
	 *
	 * @param geometry The point cloud geometry
	 */
	initialize(geometry: PointCloudBufferGeometry): void;
	/**
	 * Initialize this point cloud with a new memory layout. The contained points will be removed. Will own the geometry.
	 *
	 * @param points The first chunk of points used to infer the memory layout
	 */
	initialize(points: PointCloudChunk): void;
	/**
	 * Initialize this point cloud with a new memory layout. The contained points will be removed. Will own the geometry.
	 *
	 * @param size The size of the point cloud
	 * @param attributes The attributes to determine the memory layout
	 */
	initialize(size: number, attributes: PointCloudAttributes): void;
	/**
	 * This is the actual implementation of the two functions above.
	 *
	 * @param x A point cloud chunk or the size of the point cloud
	 * @param attributes The attributes to determine the memory layout
	 */
	initialize(x: PointCloudChunk | number | PointCloudBufferGeometry, attributes?: PointCloudAttributes): void {
		this.#geometryHandle.dispose();
		if (x instanceof PointCloudBufferGeometry) {
			this.geometry = x;
			this.#geometryHandle = new MayOwn(this.geometry, false);
		} else {
			this.geometry.initialize(x, { attributes });
			this.#geometryHandle = new MayOwn(this.geometry, true);
		}
	}

	/**
	 * Dispose all resources of this pointcloud
	 *
	 * @param disposeMaterial - True to dispose this pointcloud material
	 */
	dispose(disposeMaterial = true): void {
		this.#geometryHandle.dispose();
		this.disposePickingTree();
		if (disposeMaterial) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
			this.material?.dispose();
		}
	}

	/**
	 * @param idx index of the point
	 * @param normal the vector3 to assign the normal to
	 * @returns the normal if it exists of the point
	 */
	getPointLocalNormal(idx: number, normal?: Vector3): Vector3 | undefined {
		if (!this.normalArray) return;
		normal ??= new Vector3();

		return normal.fromArray(this.normalArray, idx * 3);
	}

	/** @returns the position attribute object */
	get positionAttribute(): Float32BufferAttribute {
		return this.geometry.positionAttribute;
	}

	/** @returns the position array */
	get positionArray(): Float32Array {
		return this.geometry.positionArray;
	}

	/** @returns the normal attribute object */
	get normalAttribute(): Float32BufferAttribute | undefined {
		return this.geometry.normalAttribute;
	}

	/** @returns the normal array */
	get normalArray(): Float32Array | undefined {
		return this.geometry.normalArray;
	}

	/** @returns the color attribute object */
	get colorAttribute(): Uint8BufferAttribute | undefined {
		return this.geometry.colorAttribute;
	}

	/** @returns the color array */
	get colorArray(): Uint8Array | undefined {
		return this.geometry.colorArray;
	}

	/** @returns the number of point to render in this pointcloud */
	get size(): number {
		return this.geometry.size;
	}

	/** @returns how many point this point cloud storage can store without a relocation */
	get capacity(): number {
		return this.geometry.capacity;
	}

	/** @returns how many point the pointcloud can append without a relocation */
	get residualCapacity(): number {
		return this.geometry.residualCapacity;
	}

	/** @returns the attributes used in this pointcloud */
	get attributes(): PointCloudAttributes {
		return this.geometry.allocatedAttributes;
	}

	/**
	 * Increase the capacity to allow space for more points, will relocate the memory
	 *
	 * @param increase the number of points we want to store more than the current capacity
	 */
	grow(increase: number): void {
		this.geometry.grow(increase);
	}

	/**
	 * Append a chunk of points to this pointcloud
	 *
	 * @param chunk The chunk of points
	 * @param canGrow true if the pointcloud can grow its memory to make space for the chunk
	 * @returns true if we were able to append the points
	 */
	append(chunk: PointCloudChunk, canGrow: boolean): boolean {
		this.disposePickingTree();
		return this.geometry.append(chunk, canGrow);
	}

	/**
	 * Overwrite some points inside the pointcloud data
	 *
	 * @param chunk The chunk of points to write
	 * @param offset Index of the first point to rewrite
	 */
	set(chunk: PointCloudChunk, offset: number): void {
		this.disposePickingTree();
		this.geometry.set(chunk, offset);
	}

	/**
	 * Copy out a chunk of point from the pointcloud memory
	 *
	 * @param offset Index of the first point to copy out
	 * @param count Number of points to copy
	 * @returns a pointcloud chunk with the points
	 */
	getChunk(offset: number, count: number): PointCloudChunk {
		return this.geometry.getChunk(offset, count);
	}

	/**
	 * Replace a chunk of points with a new chunk of points
	 *
	 * @param chunk The new points
	 * @param offset Index of the first point to replace
	 * @returns The replaced points
	 */
	replace(chunk: PointCloudChunk, offset: number): PointCloudChunk {
		this.disposePickingTree();
		return this.geometry.replace(chunk, offset);
	}

	override onBeforeRender = (
		renderer: WebGLRenderer,
		scene: Scene,
		camera: Camera,
		geometry: BufferGeometry,
		material: Material,
	): void => {
		if (material instanceof LitPointsMaterial) {
			material.color.copy(material.vertexColors ? DEFAULT_CHUNK_COLOR : this.geometry.options.color);
			material.uniforms.diffuse = makeUniform(material.color);
			if (this.pointSizeOverride) {
				if (!this.#oldPointSize) {
					this.#oldPointSize = material.size;
				}
				material.uniforms.size.value = this.pointSizeOverride;
			}
			material.uniformsNeedUpdate = true;
		}
	};

	override onAfterRender = (
		renderer: WebGLRenderer,
		scene: Scene,
		camera: Camera,
		geometry: BufferGeometry,
		material: Material,
	): void => {
		if (material instanceof LitPointsMaterial && this.#oldPointSize) {
			material.uniforms.size.value = this.#oldPointSize;
			material.uniformsNeedUpdate = true;
		}
	};

	/**
	 * @param index index of the point
	 * @param point vector3 to assign the point position to
	 * @returns the position of the point
	 */
	getLocalPoint(index: number, point?: Vector3): Vector3 {
		point ??= new Vector3();
		return point.fromArray(this.positionArray, index * 3);
	}

	/**
	 * @param index index of the point
	 * @param numNeighbours number of neighbors to find
	 * @param maxDistance max distance from the point, used to validate a neighbor
	 * @returns the list of neighbors if they exist
	 */
	collectNeighbours(index: number, numNeighbours: number, maxDistance: number): number[] | undefined {
		const { positionArray } = this;

		// Get the point position
		const startingPoint = this.getLocalPoint(index);

		type Point = {
			distance: number;
			index: number;
		};
		const distances: Point[] = [];

		const position = new Vector3();
		// Find the distances of the points from the clicked point
		for (let i = 0; i < positionArray.length / 3; i++) {
			this.getLocalPoint(i, position);

			const distance = position.sub(startingPoint).lengthSq();
			if (distance < maxDistance) {
				distances.push({ distance, index: i });
			}
		}
		if (distances.length < numNeighbours) {
			return;
		}
		// From closest to farthest
		return distances
			.sort((p1, p2) => p1.distance - p2.distance)
			.slice(0, numNeighbours)
			.map((x) => x.index);
	}

	/**
	 * @param index index of the point
	 * @param normal vector3 to assign the normal to
	 * @returns the normal of the point, if it was possible to estimate
	 */
	estimatePointLocalNormal(index: number, normal?: Vector3): Vector3 | undefined {
		const MAXIMUM_DISTANCE = 0.04;
		const NUMBER_OF_NEIGHBOURS = 10;
		const neighbours = this.collectNeighbours(index, NUMBER_OF_NEIGHBOURS, MAXIMUM_DISTANCE)?.map((i) =>
			this.getLocalPoint(i),
		);
		if (!neighbours) return;

		normal ??= new Vector3();
		const averagePoint = neighbours.reduce((a, b) => a.add(b)).divideScalar(neighbours.length);

		// Find the average normal between the first 10 nearest points to the clicked point
		for (let i = 0; i < neighbours.length; i++) {
			// Get two points near the clicked points
			const p0 = neighbours[i];
			const p1 = neighbours[(i + 2) % neighbours.length];

			const v0 = p0.clone().sub(averagePoint);
			const v1 = p1.clone().sub(averagePoint);

			v0.normalize();
			v1.normalize();

			// Cross product between two vectors to find normal
			const crossProduct = v0.clone().cross(v1);
			// If dot product is negative than the vectors point in two opposite directions (we don't want to sway the average normal)
			if (crossProduct.dot(normal) >= 0) {
				// Average the normal
				normal.add(crossProduct);
			} else {
				// Change the orientation of the vector
				normal.add(crossProduct.multiplyScalar(-1));
			}
		}
		normal.normalize();
		return normal;
	}

	/** Private cache to not re-allocate a quaternion for multiple computations */
	#worldQuaternion = new Quaternion();

	/**
	 * @param idx index of the point
	 * @param normal vector3 to assign the normal to
	 * @returns the normal in world space, if it was possible to get or estimate
	 */
	computePointWorldNormal(idx: number, normal?: Vector3): Vector3 | undefined {
		if (this.normalArray) {
			normal = this.getPointLocalNormal(idx, normal);
		} else {
			normal = this.estimatePointLocalNormal(idx, normal);
		}
		if (normal) {
			// Apply only the world quaternion to the normal to move it to world space
			// normalMatrix can't be used.
			// It is an inverse viewModelMatrix so it depends on the last used camera
			// to render this object
			const quaternion = this.getWorldQuaternion(this.#worldQuaternion);
			return normal.applyQuaternion(quaternion);
		}
	}

	/** @inheritdoc */
	override raycast(raycaster: Raycaster, intersects: Intersection[]): void {
		if (!this.raycasting.enabled) return;

		// Store original threshold and create a function to restore it.
		const origThreshold = raycaster.params.Points.threshold;
		raycaster.params.Points = {
			threshold: this.raycasting.threshold,
		};
		// eslint-disable-next-line func-style -- FIXME
		const restoreThreshold = (): void => {
			raycaster.params.Points = {
				threshold: origThreshold,
			};
		};

		// Check if we can use a picking tree
		const pickingTree = this.#preparePickingTree();

		if (pickingTree) {
			// Early return if the ray will not hit this node bounding box
			this.#pickingBox.makeEmpty();
			this.#pickingBox.expandByObject(this);
			if (raycaster.ray.intersectBox(this.#pickingBox, RAYCAST_BOX_TARGET) === null) return;

			// Raycast with picking tree
			pickingTree.raycast(raycaster, intersects);
		} else {
			// Default raycasting
			super.raycast(raycaster, intersects);
		}

		restoreThreshold();
	}

	/**
	 * @returns the PickingTree to use or undefined if we should not use it
	 */
	#preparePickingTree(): PickingTree | undefined {
		if (!this.raycasting.pickingTree.enabled) {
			return undefined;
		}
		if (this.raycasting.pickingTree.autoUpdate) {
			return this.updatePickingTree();
		}
		return this.pickingTree;
	}

	/**
	 * Compute or update the PickingTree for this PointCloud
	 *
	 * @returns The updated picking tree
	 */
	updatePickingTree(): PickingTree {
		if (this.pickingTree === undefined || this.pickingTree.maxDepth !== this.raycasting.pickingTree.maxDepth) {
			this.pickingTree = new PickingTree(this, this.raycasting.pickingTree.maxDepth);
		}
		return this.pickingTree;
	}

	/**
	 * Dispose the current PickingTree if it's allocated
	 */
	disposePickingTree(): void {
		this.pickingTree = undefined;
	}
}
