import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import { useExternalScript } from "../hooks/use-external-script";

/** CSS class to be used in order to disable translations for parts of the UI */
export const NO_TRANSLATE_CLASS = "notranslate";

// Name to use for the localize specific cookie
const COOKIE_NAME = "localize-language";

const DEFAULT_LANGUAGE: LocalizeLanguage = { code: "en", name: "English" };

// One year in seconds
const COOKIE_MAX_AGE = 31_536_000;

/**
 * @returns The second- and top-level domain part of the URL (e.g., ".holobuilder.com" from "viewer.holobuilder.com")
 *  Using this kind of domain for the cookie will make it accessible for all subdomains that share this part of the domain
 *
 *  Uses undefined for local development
 */
function getCookieDomain(): string | undefined {
  const { hostname } = window.location;

  // Don't set domain on localhost
  if (hostname === "localhost") return;

  const domain = hostname
    .split(".")
    // Getting the last two entries of the array
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    .slice(-2)
    .join(".");

  return `.${domain}`;
}

type TranslateFunction = <T extends string | Element>(
  /** Text or element to translate */
  input: T,

  /** An object with key:value pairs of variables that will be replaced in the input parameter */
  variables?: Record<string, string | number>,
) => T;

type LocalizeInitOptions = {
  /**
   * The Project Key
   * See https://developers.localizejs.com/docs/library-api#key
   */
  key: string;

  /**
   * If true, Localize will remember the user’s previous language choice and will translate your website to that language.
   * See https://developers.localizejs.com/docs/library-api#rememberlanguage
   */
  rememberLanguage?: boolean;

  /**
   * If set to true, Localize will automatically translate content that is added dynamically to your webpage.
   * See https://developers.localizejs.com/docs/library-api#retranslateonnewphrases
   */
  retranslateOnNewPhrases?: boolean;

  /**
   * If true, the <title> tag of the page in the <head> section of the web page will be translatable.
   * See https://developers.localizejs.com/docs/library-api#translatetitle
   */
  translateTitle?: boolean;

  /**
   * When true, the default Localize language-switching widget is hidden in your web pages (via CSS).
   * See https://developers.localizejs.com/docs/library-api#disablewidget
   */
  disableWidget?: boolean;

  /**
   * List of CSS classes for which the translation will be disabled
   * See https://developers.localizejs.com/docs/library-api#blockedclasses
   */
  blockedClasses?: string[];

  // Support all other options we have not yet documented here
  [key: string]: unknown;
};

type LocalizeType = {
  /**
   * Methods to call to initialize localizejs
   *
   * Docs: https://developers.localizejs.com/docs/library-api#initialize
   */
  initialize(options: LocalizeInitOptions): void;

  /**
   * Returns all available languages for the project.
   *
   * Docs: https://developers.localizejs.com/docs/library-api#getavailablelanguages
   */
  getAvailableLanguages(
    callback: (err: unknown, languages: LocalizeLanguage[]) => void,
  ): void;

  /**
   * Translates the page into the given language.
   *
   * Docs: https://developers.localizejs.com/docs/library-api#setlanguage
   */
  setLanguage(language: string): void;

  /**
   * Returns the language code for the current language of the page.
   * If a language hasn't been set, the language code of the source language is returned.
   *
   * Docs: https://developers.localizejs.com/docs/library-api#getlanguage
   */
  getLanguage(): string;

  /**
   * Retrieves the translation for an existing source phrase in the currently active language,
   * or adds a new source phrase using the contents in the `input` parameter.
   * `input` can be plain text or text within HTML, and can optionally contain variable data.
   *
   * Docs: https://developers.localizejs.com/docs/library-api#translate
   */
  translate: TranslateFunction;
};

type LocalizeContextData = Pick<LocalizeType, "setLanguage" | "translate"> & {
  /** True if the script is finished loading */
  isScriptLoaded: boolean;

  /** True if localize has been initialized (i.e., all functionality is available) */
  isInitialized: boolean;

  /** List of available languages */
  availableLanguages: LocalizeLanguage[];

  /** @returns the currently used language, undefined if localize is not initialized yet  */
  currentLanguage: LocalizeLanguage;
};

// Extending the window global to contain the Localize object
declare global {
  interface Window {
    Localize?: LocalizeType;
  }
}

/**
 * A language definition within localize.
 * Contains of a code (e.g., "en") and a human readable name (e.g., "English").
 * However, the code can also have 5 characters (e.g., "pt-BR" for the Portuguese language spoken in Brazil country)
 */
type LocalizeLanguage = {
  /** Code of the language, in ISO 639  */
  code: string;

  /** Human readable format of the language */
  name: string;
};

export const LocalizeContext = createContext<LocalizeContextData | undefined>(
  undefined,
);

export type LocalizeProviderProps = {
  /** Key for the project to initialize */
  projectKey: string;

  /** Localize option API https://developers.localizejs.com/docs/library-api */
  initOptions?: Partial<LocalizeInitOptions>;
};

/**
 * @returns The context that provides access to localize object.
 *  Will load the provided script URL and then call the init function of localize.
 *
 * It wraps the internal localize provider with the cookies provider to give the internal provider access to the cookies.
 */
export function LocalizeProvider({
  children,
  projectKey,
  initOptions,
}: PropsWithChildren<LocalizeProviderProps>): JSX.Element {
  return (
    <CookiesProvider
      defaultSetOptions={{
        maxAge: COOKIE_MAX_AGE,
        domain: getCookieDomain(),
        path: "/",
      }}
    >
      <LocalizeProviderIntern projectKey={projectKey} initOptions={initOptions}>
        {children}
      </LocalizeProviderIntern>
    </CookiesProvider>
  );
}

function LocalizeProviderIntern({
  children,
  projectKey,
  initOptions,
}: PropsWithChildren<LocalizeProviderProps>): JSX.Element | null {
  /** List of available languages */
  const [availableLanguages, setAvailableLanguages] = useState<
    LocalizeLanguage[]
  >([]);

  const [currentLanguage, setCurrentLanguage] =
    useState<LocalizeLanguage>(DEFAULT_LANGUAGE);

  // Load the main Localize script to a specific version
  const { isDoneLoading } = useExternalScript(
    "https://global.localizecdn.com/localize.js",
  );
  const Localize = useMemo(
    () => (isDoneLoading ? window.Localize : undefined),
    [isDoneLoading],
  );

  const [isInitialized, setIsInitialized] = useState(false);

  const [cookies, setCookie] = useCookies<
    typeof COOKIE_NAME,
    { [COOKIE_NAME]: string | undefined }
  >([COOKIE_NAME]);
  const cookieValue = useMemo(() => cookies[COOKIE_NAME], [cookies]);

  /**
   * Storing the new language in various places to make sure the logic is working as expected:
   *  - Via Localize.setLanguage for allowing Localize to store the language in local storage to work
   *  - In the local currentLanguage state to allow the app to work
   *  - In the cookie to allow other apps to access the user-selected language
   */
  const setLanguage = useCallback(
    (newLanguage: string) => {
      if (!isInitialized || !Localize) return;

      const currentLanguage = availableLanguages.find(
        (language) => language.code === newLanguage,
      );

      if (currentLanguage) {
        Localize.setLanguage(currentLanguage.code);
        setCurrentLanguage(currentLanguage);

        // Only update cookie in case the new language is not stored there yet
        // Otherwise, this could lead to an infinite loop
        if (cookieValue !== currentLanguage.code) {
          setCookie(COOKIE_NAME, currentLanguage.code);
        }
      }
    },
    [availableLanguages, isInitialized, setCookie, cookieValue, Localize],
  );

  const translate = useCallback<TranslateFunction>(
    (input, variables) => {
      if (isInitialized && Localize) {
        return Localize.translate(input, variables);
      }

      return input;
    },
    [isInitialized, Localize],
  );

  // Initialize the localize object once the script has been loaded,
  // and store available languages
  useEffect(() => {
    if (!isInitialized && Localize) {
      Localize.initialize({
        key: projectKey,
        rememberLanguage: true,
        retranslateOnNewPhrases: true,
        translateTitle: false,
        disableWidget: true,
        blockedClasses: [NO_TRANSLATE_CLASS],
        ...initOptions,
      });

      // Store the available languages
      Localize.getAvailableLanguages((err, languages) => {
        if (err) {
          // eslint-disable-next-line no-console
          console.warn("Could not get available languages");
          return;
        }

        setAvailableLanguages(languages);
      });

      setIsInitialized(true);
    }
  }, [projectKey, initOptions, isInitialized, Localize]);

  // Update current language based on user settings (cookie or localize-stored value)
  useEffect(() => {
    if (!isInitialized || !Localize) return;

    // Prioritize domain-wide cookie over local storage (which is only valid for the current sub-domain)
    const languageToUse = cookieValue ?? Localize.getLanguage();

    const currentLanguage = availableLanguages.find(
      (language) => language.code === languageToUse,
    );
    if (currentLanguage) {
      setLanguage(currentLanguage.code);
    }
  }, [isInitialized, availableLanguages, cookieValue, Localize, setLanguage]);

  const value = useMemo<LocalizeContextData>(
    () => ({
      isScriptLoaded: isDoneLoading,
      isInitialized,
      availableLanguages,
      setLanguage,
      currentLanguage,
      translate,
    }),
    [
      availableLanguages,
      currentLanguage,
      isDoneLoading,
      isInitialized,
      setLanguage,
      translate,
    ],
  );

  return (
    <LocalizeContext.Provider value={value}>
      {children}
    </LocalizeContext.Provider>
  );
}

/**
 * @returns Hook that returns the utility function to create a dialog
 */
export function useLocalize(): LocalizeContextData {
  const ctx = useContext(LocalizeContext);
  if (!ctx) {
    throw Error("useLocalize called outside the LocalizeContext");
  }
  return ctx;
}

/**
 * @returns The currently selected language used by localizejs.
 *  Will return "en" in case localize is not yet initialized.
 */
export function useLocalizeLanguage(): LocalizeLanguage {
  try {
    return useLocalize().currentLanguage;
  } catch {
    // Return default language in case the context is not there
    return DEFAULT_LANGUAGE;
  }
}

/** @returns a function for manual translation or a fallback, if the localization service is not available */
export function useTranslate(): TranslateFunction {
  const ctx = useContext(LocalizeContext);

  if (!ctx) {
    // If the localization service is not available return the untranslated input
    return (input) => input;
  }

  return ctx.translate;
}
